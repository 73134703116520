var dateToString = (d: Date, countdown: Boolean = false) => {
    const h = (d.getHours() < 10 ? "0" : "") + d.getHours();
    const m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();

    var distance = d.getTime() - Date.now();

    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (countdown)
        return (
            (hours == 0 ? "" : hours + ":")
            + (minutes < 10 ? "0" + minutes : minutes)
            + ":"
            + (seconds < 10 ? "0" + seconds : seconds)
        );

    return h + ":" + m
}
exports.dateToString = dateToString;


var stringToDate = (s: string) => {
    const timeSplit = s.split(":");
    const d = new Date();
    d.setUTCHours(+timeSplit[0]); // Convert string to number
    d.setUTCMinutes(+timeSplit[1]);
    d.setUTCSeconds(0);
    return d;
}
exports.stringToDate = stringToDate;


var planifyNotification = (name: string, d: Date, waypoint: string) => {
    const minsBefore = 15;
    var options = {
        body: "Click to copy waypoint: " + waypoint,
        onclick: navigator.clipboard.writeText(waypoint)
    }

    if (Notification.permission !== "granted") {
        Notification.requestPermission().then(function(result) {
            if (result === 'granted') {
                const delay = d.getTime() - Date.now() - minsBefore * 60000;
                setTimeout(function(){ new Notification(name + " " + dateToString(d)); }, delay);
            }
        });
    }

    const delay = d.getTime() - Date.now() - minsBefore * 60000;
    setTimeout(function(){ new Notification(name + " " + dateToString(d), options); }, delay);
}
exports.planifyNotification = planifyNotification;