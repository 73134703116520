import React from "react"

import { TimersCore } from "./TimersCore";
import { TimersHoT } from "./TimersHoT";
import { APIContext } from "../APIContext";

import { MdClose, MdCheck, MdPlaylistAddCheck } from "react-icons/md";
import { IoMdRemove } from "react-icons/io"

export class Timers extends React.Component<any, any> {
    private refreshInterval;
    private fetchInterval;

    constructor(props) {
        super(props);
        this.state = {
          now: new Date(),
          countdown: false,
          isLoading: true,
          eventsDone: []
        };
    }

    componentDidMount() {
      this.fetchEventsDone();
      this.fetchInterval = setInterval(() => this.fetchEventsDone(), 15 * 60000);
      this.refreshInterval = setInterval(() => this.setState({ now: new Date() }), 60000);
    }

    componentWillUnmount() {
      clearInterval(this.fetchInterval);
      clearInterval(this.refreshInterval);
    }


    render() {
      return (
        <div className="md:max-w-4xl xl:max-w-6xl mx-auto mb-12 mt-2">

          <div className="flex">
            <div className="flex-1">
              <img className="inline-block h-16" src={require("../images/timers.svg")} />
              <p className="inline-block font-display tracking-wide text-my_red-light text-3xl align-middle">Timers</p>
            </div>

            <div className="self-end button mb-1 hover:bg-my_dark-light" onClick={this.toggleCountdown}>
              <span>{this.state.countdown ? <MdCheck className="text-my_green-light"/> : <MdClose className="text-my_red"/>}</span>
              <span className="ml-1 text-sm">Countdown</span>
            </div>

            {this.context.APIKey &&
            <div className="self-end button ml-3 mb-1 hover:bg-my_dark-light" onClick={this.fetchEventsDone}>
              <span className="text-my_yellow">{this.state.isLoading ? <IoMdRemove className="spin" /> : <MdPlaylistAddCheck />}</span>
              <span className="ml-1 text-sm">Fetch done</span>
            </div>
            }
          </div>

          <div className="sm:block md:flex mt-2">
            <div className="flex-auto md:mr-4 sm:mr-1">
              <div className="card group mb-4">
                <div className="flex bg-my_dark-light">
                  <h3 className="pl-2 flex-1 text-my_green-light">Heart of Thorns</h3>
                  <span className="pr-2 text-sm text-my_gray-darker invisible group-hover:visible self-center">Every 2 hours</span>
                </div>
                <div className="table w-full text-my_gray">
                  <TimersHoT name="Night Bosses" start_time="00:10" id="verdant_brink_heros_choice_chest" waypoint="[&BAgIAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Octovine" start_time="01:00" id="auric_basin_heros_choice_chest" waypoint="[&BMYHAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Chak Gerent" start_time="00:30" id="tangled_depths_heros_choice_chest" waypoint="[&BPUHAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Dragon's Stand" start_time="01:30" id="dragons_stand_heros_choice_chest" waypoint="[&BBAIAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                </div>
              </div>

              <div className="card group mb-4">
                <div className="flex bg-my_dark-light">
                  <h3 className="pl-2 flex-1 text-my_orange">Path of Fire</h3>
                  <span className="pr-2 text-sm text-my_gray-darker invisible group-hover:visible self-center">Every 2 hours</span>
                </div>
                <div className="table w-full text-my_gray">
                  <TimersHoT name="Casino Blitz" start_time="00:05" id="crystal_oasis_heros_choice_chest" waypoint="[&BLsKAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Search for Buried Treasure" start_time="01:00" waypoint="[&BGsKAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Path to Ascension" id="elon_riverlands_heros_choice_chest" start_time="01:30" waypoint="[&BFMKAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Maws of Torment" id="the_desolation_heros_choice_chest" start_time="01:00" waypoint="[&BKMKAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Junundu Rising" id="the_desolation_heros_choice_chest" start_time="00:30" waypoint="[&BMEKAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Serpents' Ire" id="domain_of_vabbi_heros_choice_chest" start_time="00:30" waypoint="[&BHQKAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Forged with Fire" id="domain_of_vabbi_heros_choice_chest" start_time="00:00" waypoint="[&BO0KAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                </div>
              </div>

              <div className="card group mb-4">
                <div className="flex bg-my_dark-light">
                  <h3 className="pl-2 flex-1 text-my_blue-light">Living World</h3>
                  <span className="pr-2 text-sm text-my_gray-darker invisible group-hover:visible self-center">Every 2 hours</span>
                </div>
                <div className="table w-full text-my_gray">
                  <TimersHoT name="Palawadan" start_time="01:45" waypoint="[&BAkLAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Death-Branded Shatterer" start_time="01:15" waypoint="[&BJMLAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="Thunderhead Keep" start_time="01:45" waypoint="[&BLsLAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                  <TimersHoT name="The Oils Floes" start_time="00:45" waypoint="[&BKYLAAA=]" countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
                </div>
              </div>

            </div>

            <div className="flex-auto md:w-3/6">
              <TimersCore countdown={this.state.countdown} now={this.state.now} eventsDone={this.state.eventsDone} />
            </div>
          </div>


        </div>
        );
    }

    /**
     * Fetch done worldbosses and HoT metas
     */
    private fetchEventsDone = () => {
      this.setState({
          isLoading: true
      });

      if(this.context.isCorrect) {
          var key = this.context.APIKey;
          fetch("https://api.guildwars2.com/v2/account/worldbosses?access_token=" + key)
              .then(responseWB => responseWB.json())
              .then(dataWB =>
                fetch("https://api.guildwars2.com/v2/account/mapchests?access_token=" + key)
                  .then(responseHoT => responseHoT.json())
                  .then(dataHoT =>
                    this.setState({
                        isLoading: false,
                        eventsDone: dataWB.concat(dataHoT)
                    })
                  )
              );
      } else {
        this.setState({ isLoading: false });
      }
    }


    private toggleCountdown = () => {
        // Change interval to every second if we enable countdown
        if(!this.state.countdown)
          this.refreshInterval = setInterval(() => this.setState({ now: new Date() }), 1000);
        else
          this.refreshInterval = setInterval(() => this.setState({ now: new Date() }), 60000);

        this.setState(prevState => ({
            countdown: !prevState.countdown
        }));
    }

}

Timers.contextType = APIContext;