import React from "react"

import { MdCheck, MdExpandMore } from "react-icons/md";
import { IoIosPulse, IoIosNotifications, IoIosNotificationsOff } from "react-icons/io"
import { APIContext } from "../APIContext";

export class TimerEvent extends React.Component<any, any> {
    render() {

        const { id, name, map, waypoint, start_time, eventsDone } = this.props;

        return (
            <div className="table-row hover:bg-my_dark-light">
                <div className="table-cell pl-2 font-semibold text-my_yellow rounded">
                    <div className="inline-flex items-center">
                        <span className="mr-1">{name}</span>
                        <span>{eventsDone.includes(id) ? <MdCheck className="text-my_green-light" title={id} /> : null}</span>
                    </div>
                </div>

                <div className="table-cell py-1">
                    <div className="inline-flex items-center group relative">
                        {this._getNextDate(start_time)}
                        <MdExpandMore className="text-my_gray-darker" />
                        <div className="bg-my_dark-light p-1 card invisible group-hover:visible absolute top-0 z-10">
                            {start_time.map(date => (
                                <div className="flex items-center" key={date}>
                                    {require('./timers_utils.tsx').dateToString(date, false)}
                                    {date < this.props.now ?
                                    <IoIosNotificationsOff className="inline text-my_gray-darker" title="Already finished" />
                                    :
                                    <IoIosNotifications className="inline cursor-pointer text-my_gray-dark hover:text-my_yellow" onClick={evt => require('./timers_utils.tsx').planifyNotification(name, date, waypoint)} title="Notify me 15min before" />
                                    }

                                </div>

                            ))}
                        </div>
                    </div>
                </div>

                <div className="table-cell">{map}</div>

                <div className="table-cell group text-center">
                    <img className="inline-block align-middle cursor-pointer" onClick={evt => { navigator.clipboard.writeText(waypoint); this.context.toggleSnackBar("Copied", waypoint + " (" + name + ")") }} style={{ height: '30px' }}
                    title={waypoint}
                    src={require("../images/waypoint.png")}
                    onMouseOver={e => (e.currentTarget.src = require("../images/waypoint_hover.png"))}
                    onMouseOut={e => (e.currentTarget.src = require("../images/waypoint.png"))}
                    />
                </div>

            </div>
        );
    }

    private _getNextDate = (dates: Date[] ) => {
        const now = this.props.now;
        var min: Date = dates[0];
        var diff_min = Math.abs(now.getTime() - min.getTime()) / 36e5;
        for (let i = 1; i < dates.length; ++i) {
            const diff_curr = Math.abs(now.getTime() - dates[i].getTime()) / 36e5;
            if (diff_curr < diff_min && (dates[i] > now || diff_curr < 0.12)) { // 0.12 = 7 mins
                min = dates[i];
                diff_min = Math.abs(now.getTime() - min.getTime()) / 36e5;
            }
        }
        if(min < this.props.now)
            return (<>
                    <IoIosPulse className="text-xs text-my_red-light"/>
                    <IoIosPulse className="text-xs text-my_red-light"/>
                    <IoIosPulse className="text-xs text-my_red-light"/>
                    </>);

        return require('./timers_utils.tsx').dateToString(min, this.props.countdown);
    }

}

TimerEvent.contextType = APIContext;