import React from "react"
import SEO from "../components/seo";
import { Timers } from "../components/Timers";

const TimersPage = () => (
    <>
      <SEO title="Timers" />
      <Timers />
    </>
  )

export default TimersPage