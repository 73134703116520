import React from "react"

import { MdCheck } from "react-icons/md";
import { IoIosPulse } from "react-icons/io"
import { APIContext } from "../APIContext";

export class TimersHoT extends React.Component<any, any> {

    render() {

        const { id, name, waypoint, start_time, eventsDone } = this.props;

        return (
            <div className="table-row hover:bg-my_dark-light">

                <div className="table-cell pl-2 font-semibold text-my_yellow">
                    <div className="inline-flex items-center">
                        <span className="mr-1">{name}</span>
                        <span>{eventsDone.includes(id) ? <MdCheck className="text-my_green-light" title={id} /> : null }</span>
                    </div>
                </div>

                <div className="table-cell pr-2 group text-right">
                    <div className="text-my_gray inline-flex items-center">
                        {this._getNextHoTMeta(start_time)}
                    </div>
                    <img className="inline-block align-middle cursor-pointer ml-1" onClick={evt => { navigator.clipboard.writeText(waypoint); this.context.toggleSnackBar("Copied", waypoint + " (" + name + ")") }} style={{ height: '30px' }}
                    title={waypoint}
                    src={require("../images/waypoint.png")}
                    onMouseOver={e => (e.currentTarget.src = require("../images/waypoint_hover.png"))}
                    onMouseOut={e => (e.currentTarget.src = require("../images/waypoint.png"))}
                    />

                </div>

            </div>


        );
    }

    private _getNextHoTMeta = (start_time: String) => {
        var meta = require('./timers_utils.tsx').stringToDate(start_time);
        const now = this.props.now;
        while (now > meta) {
            var diff = now - meta;
            // Considered active if started less than 10 minutes ago
            if(Math.floor((diff/1000)/60) < 7)
                return (<>
                        <IoIosPulse className="text-xs text-my_red-light"/>
                        <IoIosPulse className="text-xs text-my_red-light"/>
                        <IoIosPulse className="text-xs text-my_red-light"/>
                        </>);
            // Meta repeats every two hours
            meta.setUTCHours(meta.getUTCHours() + 2);
        }
        return require('./timers_utils.tsx').dateToString(meta, this.props.countdown);
    }

}

TimersHoT.contextType = APIContext;