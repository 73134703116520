import React from "react"
import { TimerEvent } from "./TimerEvent";

import { JSONEvent, Event } from '../TimersApp.types';
import { APIContext } from "../APIContext";


export class TimersCore extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            events: this._getEvents(),
        };
    }

    render() {
        this.state.events.sort((a, b) => {
            const now = new Date();
            const diff_a = Math.abs(now.getTime() - this._getNextDate(a.start_time).getTime()) / 36e5;
            const diff_b = Math.abs(now.getTime() - this._getNextDate(b.start_time).getTime()) / 36e5;
            if (diff_a > diff_b)
                return 1;
            if (diff_a < diff_b)
                return -1;
            return 0;
        });

        return (
            <div className="card">
                <h3 className="text-my_red-light px-2 bg-my_dark-light">
                    Tyria
                </h3>
                <div className="table w-full text-my_gray">
                    {this.state.events.map(element => (
                        <TimerEvent id={element.id} name={element.name} map={element.map} waypoint={element.waypoint} start_time={element.start_time} key={element.name} eventsDone={this.props.eventsDone} countdown={this.props.countdown} now={this.props.now} />
                    ))}
                </div>
            </div>
        );
    }

    private _getEvents = () => {
        const JSON_Events: JSONEvent[] = require('./events.json');
        var events: Event[] = [];
        for (let e = 0; e < JSON_Events.length; ++e) {
            var start_time: Date[] = [];
            for (let i = 0; i < JSON_Events[e].start_time.length; ++i)
                start_time.push(this._stringToDate(JSON_Events[e].start_time[i]));
            events[e] = {
                ...JSON_Events[e],
                start_time
            };
        }
        return events;
    }

    private _getNextDate = (dates: Date[] ) => {
        const now = this.props.now;
        var min: Date = dates[0];
        var diff_min = Math.abs(now.getTime() - min.getTime()) / 36e5;
        for (let i = 1; i < dates.length; ++i) {
            const diff_curr = Math.abs(now.getTime() - dates[i].getTime()) / 36e5;
            if (diff_curr < diff_min && (dates[i] > now || diff_curr < 0.12)) { // 0.12 = 7 mins
                min = dates[i];
                diff_min = Math.abs(now.getTime() - min.getTime()) / 36e5;
            }
        }
        return min;
    }


    /**
     * Transform hh:mm strings to dates
     */
    private _stringToDate = (s: String) => {
        const timeSplit = s.split(":");
        const d = new Date();
        if(+timeSplit[0] < Math.abs(d.getTimezoneOffset() / 60))
            d.setDate(d.getDate() + 1);
        d.setUTCHours(+timeSplit[0]); // Convert string to number
        d.setUTCMinutes(+timeSplit[1]);
        d.setUTCSeconds(0);
        return d;
    }

}

TimersCore.contextType = APIContext;